// ----------------------------------------------------------------------

export const BASE_IMG =
  'https://res.cloudinary.com/trinhmai/image/upload/c_scale,f_auto,q_auto,';

export const BASE_IMG_ENTR = 'https://res.cloudinary.com/entr-singapore/image/upload/c_scale,f_auto,q_auto,';
export const FODLER_ENTR = 'v1620394491/entr/'
export const FODLER_ARCHIVE = 'v1620394491/marais/'
export const FODLER_SURCOUF = 'v1620394491/surcouf/'
export const FODLER_MAUBOURG = 'v1620394491/maubourg/'
export const FODLER_ROUSSELET = 'v1620394491/rousselet/'
export const FODLER_BEAUCOURT = 'v1620394491/beaucourt/'
export const FODLER_EXPOSITION = 'v1620394491/exposition'

export const getImgFeedEntr = (width, index) =>
  `${BASE_IMG_ENTR}w_${width}/${FODLER_ENTR}/test${index}.jpg`;

export const getImgFeedArchive = (width, index) =>
  `${BASE_IMG_ENTR}w_${width}/${FODLER_ARCHIVE}/archive_${index}.jpg`;

export const getImgFeedBeaucourt = (width, index) =>
  `${BASE_IMG_ENTR}w_${width}/${FODLER_BEAUCOURT}/beaucourt_${index}.jpg`;

export const getImgFeedSurcouf = (width, index) =>
  `${BASE_IMG_ENTR}w_${width}/${FODLER_SURCOUF}/surcouf_${index}.jpg`;

export const getImgFeedMaubourg = (width, index) =>
  `${BASE_IMG_ENTR}w_${width}/${FODLER_MAUBOURG}/maubourg_${index}.jpg`;

export const getImgFeedRousselet = (width, index) =>
  `${BASE_IMG_ENTR}w_${width}/${FODLER_ROUSSELET}/rousselet_${index}.jpg`;

export const getImgFeedExposition = (width, index) =>
  `${BASE_IMG_ENTR}w_${width}/${FODLER_EXPOSITION}/exposition_${index}.jpg`;



export const getImgCover = (width, index) =>
  `${BASE_IMG}w_${width}/v1611411356/upload_minimal/covers/cover_${index}.jpg`;

export const getImgFeed = (width, index) =>
  `${BASE_IMG}w_${width}/v1611420989/upload_minimal/feeds/feed_${index}.jpg`;

export const getImgProduct = (width, index) =>
  `${BASE_IMG}w_${width}/v1611420989/upload_minimal/products/shose_${index}.jpg`;

export const getImgAvatar = (index) =>
  `/static/images/avatars/avatar_${index}.jpg`;

// Only Home Page
export const getImgComponent = (width, name, mode) =>
  `${BASE_IMG}w_${width}/v1611426110/upload_minimal/components/${name}_${mode}.jpg`;
