import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_APP, PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.general.dashboard,
        audiance : 'INVEST'
      },     
      
      
      {
        title: 'secondary market',
        href: PATH_APP.general.secondary,
        audiance : 'INVEST'
      },


          {
            title: 'ENTR Paris I',
            href: PATH_APP.general.paris1,
            audiance : 'INVEST'
          },
          {
            title: 'ENTR Paris I - NEW',
            href: PATH_APP.general.paris1New,
            audiance : 'NEW'
          },
          {
            title: 'ENTR Paris I / Marais',
            href: PATH_APP.general.marais,
            audiance : 'INVEST'
          },
          {
            title: 'ENTR Paris I / Surcouf',
            href: PATH_APP.general.surcouf,
            audiance : 'INVEST'
          },
          {
            title: 'ENTR Paris I / Tour Maubourg',
            href: PATH_APP.general.maubourg,
            audiance : 'INVEST'
          },
          {
            title: 'ENTR Paris I / Rousselet',
            href: PATH_APP.general.rousselet,
            audiance : 'INVEST'
          },
          {
            title: 'ENTR Paris I / Beaucour',
            href: PATH_APP.general.beaucour,
            audiance : 'INVEST'
          },
          {
            title: 'ENTR Paris I / Exposition',
            href: PATH_APP.general.exposition,
            audiance : 'INVEST'
          }
    ]
  },
];

export default navConfig;
